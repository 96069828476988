.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  background: #000;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.gradient-text {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#0082f3),
    to(#0ef0ea)
  );
  background-image: linear-gradient(90deg, #0082f3, #0ef0ea);
  -webkit-filter: saturate(150%);
  filter: saturate(150%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fontInter800 {
  font-family: "Inter", sans-serif;
  font-weight: 800;
}

.fontInter700 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
}

.fontInter500 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
}

.fontInter600 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

.fontNunito600 {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
}
.fontNunito500 {
  font-family: "Nunito", sans-serif;
  font-weight: 500;
}
.fontNunito800 {
  font-family: "Nunito", sans-serif;
  font-weight: 800;
}
.fontNunito400 {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
}

.bg-layers {
  -webkit-animation: filter-animation 4s infinite;
  animation: filter-animation 4s infinite;
}

.bg-scale {
  -webkit-animation: filter-animation 8s infinite;
  animation: filter-scale 8s infinite;
}

@-webkit-keyframes filter-animation {
  0% {
    -webkit-filter: hue-rotate(0deg);
  }

  50% {
    -webkit-filter: hue-rotate(45deg);
  }

  100% {
    -webkit-filter: hue-rotate(0deg);
  }
}

@keyframes filter-animation {
  0% {
    filter: hue-rotate(0deg);
  }

  50% {
    filter: hue-rotate(45deg);
  }

  100% {
    filter: hue-rotate(0deg);
  }
}

@keyframes filter-scale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(0.8);
  }
}
